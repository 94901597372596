
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.main-container, .contact, .main-title{
    max-width: 1280px;
    margin:0 auto;
    padding:0 10px;
}



li{
    list-style: none;
}

a{
    text-decoration: none;
}

.title{
    letter-spacing: 0.1rem;
    font-style: italic;
    border-bottom: 1px dashed rgb(9,93, 119);
    color:rgb(9,93,119);
    margin:0 auto;
    margin-bottom: 20px;
    max-width: 1280px;
}


/* ..........Header................ */
.header{
    width: 100%;
    height: 400px;
    /* max-width: 1400px; */
    margin:0 auto;
    position: relative;
}

.particle{
    position: relative;
    width: 100%;
    height: 100%;
}

.particleComp{
    background-color: rgb(17,115,145);
}

.fullName{
    position:absolute;
    top:6rem;
    text-align: center;
    left:50%;
    right: 0;
    width: 200px;
    transform: translate(-50%, -50%);

}

.fullName h1{
    color: rgb(241,240,240);
    letter-spacing: 0.1rem;
    font-style: italic;
    text-shadow: 1px 1px 2px black, 0 0 1em rgb(21,164,221), 0 0 0.2em rgb(17,17,17);

}

.cv{
    position: absolute;
    top:3rem;
    right:1rem;

}

.cv span b{
    font-size: 20px;
    text-transform: capitalize;
    color:rgb(231,229,229);
    letter-spacing: 0.1rem;
    font-style: italic;

}

.cv span .fa-file-pdf{
    color:white;
    font-size: 25px;
    background-color: red;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;

}

.cv:hover .fa-file-pdf{
    transform: scale(1.2);
}


.personalInfo-details .info{
  border:1px solid lightgray;
  margin:5px 0;
  padding: 5px;
  letter-spacing: 0.1rem;
  font-style: 16px;
  width: 100%;

}

.personalInfo-details .info label{
    text-decoration: underline;
    color:white;
}

.personalInfo-details .info h4{
    font-style: italic;
    color:rgb(236,235,235);
    margin-top: 5px;

}

.personalInfo{
    position: relative;
    box-shadow: 1px 1px 4px 3px rgba(197,196,197, 0.479);
    width: 98%;
    margin:0 auto;
    padding: 5px;
    transition: all 0.3s ease-in-out;
    margin-top: -100px;
}

.personalInfo-center{
    display: flex;
    width: 100%;
    margin:0 auto;
    justify-content: space-between;
    padding: 5px;
    background-color: rgb(9,93,119);
    border-radius: 5px;
    
}

.personalInfo-details{
    flex: 0 0 calc(68% - 10px);

}

.personalInfo-img{
    flex: 0 0 calc(32% - 10px);
    display: block;
    align-self: center;
}

.personalInfo-img img{
 width: 100%;
 border-radius: 5px;
 border:1px solid lightgray;
 padding: 5px;

}

@media screen and (min-width:664px){
 .fullName{
     top:4rem;
     width: 300px;
 }

.personalInfo{
    max-width: 460px;
    width: 100%;
    margin-top: -100px;

}

.personalInfo-details{
    flex:0 0 calc(64% - 10px);

}

.personalInfo-img{
    flex:0 0 calc(36% - 10px);
    display: block;
    align-self: center;

}


}


@media screen and (min-width:960px){
    .fullName{
      left:25%;
    }
   
  .fullName h1{
      font-size:40px;
      position: relative;
      padding: 5px;
  }

 .cv{
     top:2rem;
     right:4rem;

 }

 .personalInfo{
     position: relative;
 }
   
   
   }
   

/* ..........end of Header................ */


/* ..........About................ */

.about{
    margin:20px 0;

}

.about-info p{
 font-size:18px;
 color:rgb(48, 47,47);
 line-height: 1.5;

}



/* ..........end of about................ */


/* ..........Education................ */

 .education{
     margin:20px 0;

 }

 .single-education{
     background: rgb(17,115,145);
     margin:0 auto;
     margin-bottom: 20px;
     width:100%;
     font-size: 18px;
     color:white;
     padding: 5px 10px;
     letter-spacing: 0.1rem;
 }

 @media screen and (min-width:860px){
  
    .education-center{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .single-education{
        flex:0 0 calc(50% - 20px);
        margin-right: 0;
        margin-left: 0;
        padding: 10px;
        clip-path: polygon(0  0,100% 0,95% 100%, 0% 100%);
    }
 }

/* ..........end of Education................ */

/* ..........Projects................ */

.single-project{
    width:100% ;
    margin:0 auto;
    box-shadow: 0 0 4px 1px gray;
    margin-bottom: 30px;
}

.single-project-img{
    width:100%;
    margin:0 auto;
    height: 350px;

}

.single-project-img img{
   width: 100%;
   height: 100%;
   display: block;
   padding: 10px;

}

.single-project-info{
    padding: 10px;

}

.single-project-info h3{
    letter-spacing: 0.1rem;
    margin-bottom: 5px;
    color:rgb(9,93,119);
}

.single-project-info p{
   color:#424242;
   font-size: 18px;

}


@media screen and (min-width:664px){
 .projects-center{
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }

 .single-project{
     display: flex;
 }

 .single-project-img{
     width:300px;
     height: 280px;

 }

 .single-project-img img{
    width:300px;
    height: 280px;

}



}

@media screen and (min-width:964px){
    .projects-center{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        display: flex;
        max-width: 1180px;
        margin:0 auto;
    }
   
    .single-project{
        display: flex;
        flex:0 0 calc(49%);
        margin:0 0 ;
        margin-bottom: 25px;
        position: relative;
    }
   
    .single-project-img{
        width:250px;
        height: 250px;
   
    }
   
    .single-project-img img{
       width:250px;
       height: 250px;
       transition: all 0.3s ease-in-out;
   }

   .single-project:hover .single-project-img img{
       transform: scale(1.05);
   }

   }


/* ..........end of Projects................ */

/* ..........Experience................ */
.experience-center{
    margin-top: 20px;
}

.single-experience{
 
    margin-bottom: 20px;
    width: 100%;
    font-size: 18px;
    color:white;
    padding: 5px 10px;
    letter-spacing: 0.1rem;
    background: rgb(191,192,192);
    color:#424242;

}

@media screen and (min-width:860px){
 .experience-center{
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }

 .single-experience{
     flex:0 0 calc(50% - 20px);
     margin-right: 0;
     margin-left: 0;
     padding: 10px;
 }
}



/* ..........end of Experience................ */

/* ..........Footer................ */
.main-contact{
    background:rgb(9,93,119);
}

.contact-center{
    padding: 20px 0;
}

.contact-center-links{
    margin-bottom: 20px;
}

.contact-center-links h3, 
.contact-center-media h3{
 text-align: center;
 letter-spacing: 0.2rem;
 color:lightgray;
 margin-bottom: 10px;

}

.contact-links{
    align-items: center;
    display: flex;
    flex-direction: column;

}

.contact-media{
    display: flex;
    justify-content: center;
}

.contact-links li{
    margin-bottom: 10px;
    color:rgb(192,190,190);
}

.contact-links li a{
    margin:0 5px;
    color:rgb(192,190,190);
    font-size: 18px;
    display: inline-block;
    padding: 0 2px;
}

.contact-links li a:hover{
    margin:0 5px;
    color:rgb(226,225,225);

}

.contact-media li a{
    margin:0 5px;
    color:rgb(192,190,190);
    padding: 5px;
    font-size: 18px;
}

.contact-media li a:hover{
    color:rgb(226,225,225);
}

.contact-media li a i{
    margin: 0 5px;
    color:rgb(192,190,190);
    font-size: 20px;
}


.footer{
    border-top: 1px solid lightgray;
    text-align: center;
    color:lightgray;
    font-style: italic;
    padding: 10px 0;
    font-size: 18px;
}


@media screen and (min-width:530px){
 .contact-links li{
     margin-bottom: 0;
 }

 .contact-links, .contact-media{
     display: flex;
     flex-direction: row;
     justify-content: center;
 }



}

@media screen and (min-width:864px){
    .contact-media li a i{
        font-size: 24px;
    }


   
   
   }

   @media screen and (min-width:864px){
  .contact-center{
      display: flex;
      justify-content: space-evenly;
  }
    
   
   
   }



/* ..........end of Footer................ */