
.same-form{
    box-shadow: 0 0 2px 1px rgb(11,72,92);
    padding:10px;
    width: 100%;
}

.admin-title{
    color:#555;
    letter-spacing: 0.2rem;
    font-style: italic;
    margin-bottom: 10px;
}

.same-form form{
    display: flex;
    flex-direction: column;
}

.same-form label{
    letter-spacing: 0.1rem;
    margin-top: 10px;
    margin-bottom: 3px;
    color:#424242;

}

form h4{
    color:#555;
    letter-spacing: 0.2rem;
    font-style: italic;
    display: none;
}

form textarea{
    padding:7px;
    border:1px solid rgb(40,185,185);
    outline: none;

}

form input{
    padding:7px;
    border:1px solid rgb(40,185,185);
    outline: none;

}

form button{
    margin-top: 15px;
    padding:5px;
    background: rgb(19, 126, 233);
    outline: none;
    border: none;
    cursor: pointer;
    color:rgb(230,227,227);
    font-size: 16px;
    letter-spacing: 0.1rem;
    width: 140px;


}

form button:hover{
  
    background: dodgerblue;
}


.same-item{
 margin:20px 0;
 border:1px solid lightgray;
 padding:10px;
 position: relative;
}

.item-delete-tab{
    background: crimson;
    text-align: center;
    font-style: italic;
    color:white;
    font-weight: 400;
    width: 400px;
    margin:0 auto;
    position: absolute;
    top:5px;
    left:50%;
    display: block;
    transform: translateX(-50%);


}

.same-item .icons{
    text-align: right;
    margin:0 10px;
    font-size: 18px;
}

.same-admin .single-education{
    margin-top: 10px;
}

.projects-admin .single-project{
 margin-top: 10px;
} 

.fa-trash{
    color:crimson;
    margin-left: 20px;
}

.fa-edit{
    color:green;
    margin-left:10px;
}

.fa-trash:hover, .fa-edit:hover{
    opacity: 0.9;
    cursor: pointer;
  
}



/* responsive */
@media screen and (min-width:664px){
.same-component{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.same-form{
    flex:0 0 300px;
}

.same-item{
    flex:1;
    margin:0;
    margin-left: 30px;
}


}


@media screen and (min-width:664px){
  
    .same-form{
        flex:0 0 400px;
    }
 
 }



 /* education admin */

.same-admin{
    margin-bottom: 5px;

}

.same-admin .single-education p{
 color:lightgray;
}

/* projects */
.projects-admin .single-project-img{
    width: 100%;
    height: 300px;

}

.projects-admin .single-project-img img{
    width: 100%;
    height: 100%;

}

@media screen and (min-width:574px){
 .projects-admin .single-project-img{
     width: 100%;
     height: 350px;
 }

 .projects-admin .single-project-img img{
    width: 100%;
    height: 100%;
 }
}


@media screen and (min-width:664px){

    .projects-admin .single-project{
        display: block;
    }

    .projects-admin .single-project-img{
        width: 275px;
        height: 180px;
    }
   
    .projects-admin .single-project-img img{
       width: 100%;
       height: 100%;
    }

    .single-project-info{
        flex: 1;
    }
   }


   @media screen and (min-width:664px){

    .projects-admin .single-project-img{
       width:150px;
       height:150px;
    }

    .projects-admin .single-project-img img{
        width: 150px;
        height: 100%;
    }
   
  .projects-admin .single-project{
      display: flex;
      justify-content: space-between;

  }

   
   }


   /* upload */
   .upload{
       display: flex;
       justify-content: space-between;
       margin-top: 20px;
       position: relative;
   }

   .upload input{
       align-self: flex-start;
       width:200px;
   }

   #file_img{
      
       position: relative;
       width:115px;
       height: 45px;
       margin-right: 10px;
       margin-top: 10px;
       margin-left: 10px;
   }

   .upload img{
     width: 115px;
     display: block;
     height: 90px;
   }

   #file_img span{
       position: absolute;
       top:-10px;
       right:-7px;
       cursor: pointer;
       color:crimson;
       background: lightgray;
       width:20px;
       text-align: center;
       border-radius: 50%;
   }




/* back to Home */
.back_to_home{
    min-height: 60vh;
    display: flex;
    justify-content: center;
}

.back_box{
    margin-top: 50px;
}

.back_box h2{
    letter-spacing: 0.1rem;
    color:crimson;
    margin-bottom: 20px;
    font-style: italic;
    text-decoration: underline;
}

.back_box a{
    background-color: dodgerblue;
    padding:5px 10px;
    color: white;
    font-size: 18px;
}

.back_box a:hover{
    background-color: rgb(82, 156, 230);
}





