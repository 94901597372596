
.login{
    background: #fff;
    padding: 40px;
    min-height: 75vh;
}

.login h3{
    text-align: center;
    padding-bottom: 10px;
    color:#424242;
    letter-spacing: 0.1rem;
}

.login-center{
    max-width: 400px;
    margin:0 auto;
}

.login-center form{
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 2px 1px rgb(11,72,92);
    padding:20px;
}

.login-center form p{
    margin-bottom: 10px;
    text-align: center;
    color: crimson;
    letter-spacing: 0.1rem;
}

.login-center form label{
    margin-bottom: 5px;
    letter-spacing: 0.2rem;
    color:#555;
}

.login-center form input{
    padding:7px;
    border:1px solid rgb(40,185,185);
    outline: none;
}

.login-center form input:nth-of-type(1){
    margin-bottom: 10px;
}

.login-center .login-btn{
    width:100%;
    display: flex;
    justify-content: space-between;
}

.login-center button{
    margin-top: 15px;
    padding:5px;
    background: rgb(19,126,233);
    outline: none;
    border:none;
    cursor: pointer;
    color:rgb(230,227,233);
    font-size: 16px;
    letter-spacing: 0.1rem;
    width: 160px;
    margin-left: 5px;
    margin-right: 5px;

}


.login-center button:hover{
   
    background: dodgerblue;
 
}


